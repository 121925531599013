export type IBlog = {
    name: string;
    description: string;
    image: string;
    title: string;
    filePath: string;
}

export const BLOGS: IBlog[] = [
    {
        name: "agi",
        title: "AGI Is Here, Why You Still Need to Learn to Program",
        image: `${process.env.PUBLIC_URL}/images/posting8-agi.png`,
        description: "Even if AGI arrives soon, human programmers remain essential for building, customizing, and maintaining truly effective software solutions. While AI tools can generate boilerplate code or assist with repetitive tasks, it’s still the creative, critical thinking of skilled developers that ensures these systems stay efficient, adaptable, and aligned with real-world needs.",
        filePath: `${process.env.PUBLIC_URL}/posts/agi.md`,
    },
    { 
        name: "ai", 
        title: "The Future of Low-Code and Visual Programming for AI-Driven Designs",
        image: `${process.env.PUBLIC_URL}/images/posting7.png`,
        description: "As AI takes on a larger role in automating code generation, low-code and visual programming are poised to redefine software development. By harnessing visual design tools, developers and non-developers alike can collaborate more effectively, creating software that is more intuitive, maintainable, and accessible. This critical analysis explores how visual programming lies at the heart of comprehensive AI-driven systems, serving as bridge between technical implementation and smart assistants for the future of software engineering.",
        filePath: `${process.env.PUBLIC_URL}/posts/ai.md`,
    },
    { 
        name: "how-to-choose", 
        title: "How to Choose the Right Low-Code, No-Code, or Process Automation Platform",
        image: `${process.env.PUBLIC_URL}/images/posting1.png`,
        description: "Choosing the right low-code, no-code, or process automation platform involves evaluating scalability, integration, customization, and user-friendliness. Divooka by Methodox Technologies excels with its modular architecture, seamless C# and Python integration, and intuitive interface, supporting both workflow automation and everyday computational tasks with minimal setup and high manageability.",
        filePath: `${process.env.PUBLIC_URL}/posts/how-to-choose.md`,
    },
    { 
        name: "hard-to-make", 
        title: "The Challenges of Making A General Purpose Visual Programming Platform",
        image: `${process.env.PUBLIC_URL}/images/posting2.png`,
        description: "Creating a general-purpose visual programming platform is challenging due to the need for balancing accessibility, versatility, and scalability while overcoming technical hurdles like complex logic representation and performance optimization. It's more of an art than engineering problem. This article explores these challenges and highlights the gap between domain-specific visual tools and general-purpose text-based languages.",
        filePath: `${process.env.PUBLIC_URL}/posts/hard-to-make.md`,
    },
    { 
        name: "workflow-sharing", 
        title: "The Problem and Challenges with Sharing in Program Development and The Visual Paradigm",
        image: `${process.env.PUBLIC_URL}/images/posting3.png`,
        description: "Efficient sharing in visual programming is crucial for rapid iteration and creative problem-solving, yet existing platforms often struggle with reusability and accessibility due to technical dependencies and organizational challenges. Divooka addresses these issues by offering a flexible, cross-platform solution that promotes modularity and seamless collaboration.",
        filePath: `${process.env.PUBLIC_URL}/posts/workflow-sharing.md`,
    },
    { 
        name: "subgraphs", 
        title: "Subgraphs: Essential Building Blocks for Visual Programming Platforms",
        image: `${process.env.PUBLIC_URL}/images/posting4.png`,
        description: "Subgraphs are a vital ingredient in visual programming platforms, enabling the breakdown of complex tasks into manageable components, enhancing abstraction, readability, and collaboration. Divooka supports both document referencing and embedded subgraphs, providing flexibility for different project needs.",
        filePath: `${process.env.PUBLIC_URL}/posts/subgraphs.md`,
    },
    {
        name: "dsl", 
        title: "Unlocking the Power of Node-Based Interfaces for DSL Implementation",
        image: `${process.env.PUBLIC_URL}/images/posting5-dsl.png`,
        description: "When considering the implementation of Domain-Specific Languages (DSL) and the challenges posed by traditional microservices architecture, Divooka emerges as a powerful and extensible visual programming platform. Integrating C# and Python, Divooka provides a low-cost, low-overhead solution for automating business processes, streamlining development, and enhancing data access with its user-friendly node-based interface.",
        filePath: `${process.env.PUBLIC_URL}/posts/dsl.md`,
    },
    { 
        name: "education", 
        title: "The Power of Visual Programming in Education: Going Beyond the Basics with Divooka",
        image: `${process.env.PUBLIC_URL}/images/posting6.png`,
        description: "Divooka by Methodox Technologies is a powerful visual programming platform that grows with learners, offering an easy-to-use interface for beginners while providing advanced features for more experienced coders. It's a tool that takes students from their first steps in programming to real-world applications, making coding accessible and engaging at every level.",
        filePath: `${process.env.PUBLIC_URL}/posts/education.md`,
    }
]
