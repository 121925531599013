import styled  from "styled-components";
import { BoundedContent } from "../../BoundedContent";
import charles from "./charles-zhang-profile.jpg";
import edison from "./edison-li-profile.jpg";

const Container = styled(BoundedContent)`
${props => props.theme.boundedContent}
align-items: center;
display: flex;
flex-direction: column;
gap: ${props => props.theme.spacing.medium};
`

const Heading = styled.div`
font-size: ${props => props.theme.spacing.xlarge};
font-weight: 700;
`;

const Profile = styled.img`
border-radius: 0.2rem;
overflow: hidden;

width: 100%;
max-width: 300px;
margin-left: auto;
margin-right: auto;
${props => props.theme.breakpoints.medium}{
  width: 25%;
}
`;

const Row = styled.div<{ isReverse? : boolean }>`
width: 100%;
display: flex;
flex-wrap: wrap;
flex-direction: ${props => props.isReverse ? "row-reverse" : "row"};
align-items: flex-start;
gap: ${props => props.theme.spacing.medium};
`

const Content = styled.div`
display: flex;
flex-direction: column;
gap: ${props => props.theme.spacing.small};

width: 100%;
${props => props.theme.breakpoints.medium}{
  width: 70%;
}
`

const Name = styled.span`
font-size: ${props => props.theme.spacing.small};
color: ${props => props.theme.palette.l1};
`

const Title = styled.span``;

const Description = styled.span`
line-height: ${props => props.theme.spacing.medium};
`


export function Team () {
  return (
    <Container>
      <Heading>Methodox Team</Heading>
      <Row>
        <Profile
          alt="Charle's profile"
          src={charles}
        />
          <Content>
            <Name>Charles Zhang</Name>
            <Title>Founder, Lead Developer</Title>
            <Description>
              Gamer, developer, analyst, writer and world builder. Graduated from the University of Toronto with a degree in Computer Engineering. Previously worked at Baycrest Hospital and Blackbird Interactive, former developer on the Minecraft Legends game, as well as a Financial Analyst at the Ontario Teachers' Pension Plan. Extensive knowledge about risk, investment, VFX, gaming and software development industry, with unique insight in full-stack development and expertise in desktop programming. Now crafting the next-gen computing platform for the AI era.
              <br/>
              Contact: Charles.Zhang@Methodox.io
            </Description>
          </Content>
      </Row>
      <Row>
        <Profile
          alt="Edison's profile"
          src={edison}
        />
          <Content>
            <Name>Edison Li</Name>
            <Title>Vice President</Title>
            <Description>
              Graduated from the University of Toronto with a degree in International Development Studies and Economics. Passionate about technology, investing, and innovation, actively engages as a competitive tennis player, motorsport racer, and gamer. Skilled in project management, strategic investing and market analysis, and analytical thinking and problem-solving. With a background in economics and a keen interest in cutting-edge technology, Edison has joined the Methodox team inspired by Charles's vision, committed to driving innovation and shaping the future of computing platforms.
              <br/>
              Contact: Edison.Li@Methodox.io
            </Description>
          </Content>
      </Row>
    </Container>
  );
}



