import { Blogs } from "./Blogs/Blogs";
import { Contact } from "./Contact/Contact";
import { Details } from "./Details/Details";
import { FAQ } from "./FAQ/FAQ";
import { Team } from "./Team/Team";
import { Introduction } from "./Introduction/Introduction";
import { Landing } from "./Landing/Landing";

export function Home() {
  return (
      <>
          <Landing />
          <Introduction />
          <Details />
          <Team />
          <Blogs />
          <FAQ />
          <Contact />
      </>
  );
}
